import React, { useRef, useState, useEffect } from 'react';
import {
    IonButton,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonNote,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonText,
    IonTitle,
    IonToggle,
    IonToolbar,
    IonButtons,
    useIonModal,
    IonDatetime,
    IonDatetimeButton,
    IonPopover,
} from '@ionic/react';
import {
    arrowBack,
    banOutline,
    close,
    musicalNoteOutline,
    volumeHighOutline,
    addOutline,
    removeCircle,
} from 'ionicons/icons';
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react';
import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';

import styles from './AddDoorPolicyModal.module.css';
import { TransitPolicy, TransitPolicyRule, TransitPolicyRuleCriteria } from '../models/TransitPolicy';
import { EventTriggerSource } from '../models/DeviceEvent';
import { useDeviceTransitPolicyCreateMutation } from '../query/deviceTransitPoliciesQuery';
import useUX from '../utils/ux';
import SelectRfidModal from './SelectRfidModal';
import { modalController } from '@ionic/core';
import RfidChip from '../components/RfidChip';
import BetaChip from '../components/BetaChip';
import JsonViewerModal from './JsonViewerModal';
import { useDeveloperModeQuery } from '../query/developerModeQuery';

enum PAGES {
    POLICY_TYPE = 0,
    POLICY_NAME = 1,
}

enum PERMISSION {
    ALLOW = 'allow',
    DENY = 'deny',
    ALLOWLIST = 'allowlist',
}

interface PolicyTemplate {
    icon: string;
    description: string;
    defaults: {
        policyName: string;
        transitPolicy: TransitPolicy;
    };
}

const POLICY_TEMPLATE: { [key: string]: PolicyTemplate } = {
    UNLOCK_FOR_ALL: {
        icon: '/assets/lock/lock-unlocked.svg',
        description: 'The door unlocks when approached from either side.',
        defaults: {
            policyName: 'Unlock for All',
            transitPolicy: new TransitPolicy({
                rules: [
                    new TransitPolicyRule({
                        action: { lock: false },
                        criteria: { eventTriggerSource: EventTriggerSource.IndoorMotion },
                        description: 'Exit Rule',
                    }),
                    new TransitPolicyRule({
                        editorKey: 'contraband',
                        action: { lock: true, sound: 'alarm' },
                        criteria: {
                            eventTriggerSource: EventTriggerSource.OutdoorMotion,
                            eventClassification: [2, 3],
                        },
                        description: 'Contraband Rule',
                    }),
                    new TransitPolicyRule({
                        editorKey: 'entry',
                        action: { lock: false },
                        criteria: { eventTriggerSource: EventTriggerSource.OutdoorMotion },
                        description: 'Entry Rule',
                    }),
                ],
            }),
        },
    },
    CUSTOM: {
        icon: '/assets/lock/lock-smart.svg',
        description:
            'Allow entry/exit for specific pets. Set curfews to specify allowed hours.',
        defaults: {
            policyName: 'Custom Policy',
            transitPolicy: new TransitPolicy({
                rules: [
                    new TransitPolicyRule({
                        editorKey: 'exit',
                        action: { lock: false },
                        criteria: { eventTriggerSource: EventTriggerSource.IndoorMotion },
                        description: 'Exit Rule',
                    }),
                    new TransitPolicyRule({
                        editorKey: 'contraband',
                        action: { lock: true, sound: 'alarm' },
                        criteria: {
                            eventTriggerSource: EventTriggerSource.OutdoorMotion,
                            eventClassification: [2, 3],
                        },
                        description: 'Contraband Rule',
                    }),
                    new TransitPolicyRule({
                        editorKey: 'entry',
                        action: { lock: false },
                        criteria: { eventTriggerSource: EventTriggerSource.OutdoorMotion },
                        description: 'Entry Rule',
                    }),
                ],
            }),
        },
    },
    TRAINING: {
        icon: '/assets/lock/lock-unlocked.svg',
        description:
            'The door is left unlocked. The lock does not click on approach.',
        defaults: {
            policyName: 'Training',
            transitPolicy: new TransitPolicy({
                idleLock: false,
            }),
        },
    },
    LOCKED: {
        icon: '/assets/lock/lock-locked.svg',
        description: 'The door remains locked. No entry or exit will be allowed.',
        defaults: {
            policyName: 'Locked',
            transitPolicy: new TransitPolicy({
                rules: [
                    new TransitPolicyRule({
                        action: { lock: true },
                    }),
                ],
            }),
        },
    },
};

interface AddDoorPolicyModalProps {
    deviceId: string;
    dismiss: (data?: string | null | undefined | number, role?: string) => void;
}

const AddDoorPolicyModal: React.FC<AddDoorPolicyModalProps> = ({ deviceId, dismiss }) => {
    const [slideIndex, setSlideIndex] = useState<number>(0);
    const swiperRef = useRef<SwiperClass | null>(null);
    const { presentResult } = useUX();

    const [policyName, setPolicyName] = useState<string>('');
    const [selectedTemplateKey, setSelectedTemplateKey] = useState<string | null>(null);
    const [transitPolicy, setTransitPolicy] = useState<TransitPolicy | null>(null);
    const mutation = useDeviceTransitPolicyCreateMutation(deviceId);
    const developerModeQuery = useDeveloperModeQuery();
    const developerMode = !!developerModeQuery.data;

    const selectPolicyTemplate = (templateKey: string) => {
        const template = POLICY_TEMPLATE[templateKey];
        swiperRef.current?.slideNext();
        setPolicyName(template.defaults.policyName);
        setTransitPolicy(template.defaults.transitPolicy);
        setSelectedTemplateKey(templateKey);
    };

    const createPolicy = () => {
        presentResult(async () => {
            if (!transitPolicy) {
                throw new Error('Transit Policy is required');
            }
            const cleanedPolicy = cleanTransitPolicy(transitPolicy);
            await mutation.mutateAsync({
                deviceId,
                name: policyName,
                transitPolicy: cleanedPolicy,
            });
            dismiss();
        }, 'Door Policy Created');
    };

    function adjustEndTime(time: string): string {
        let [hoursStr, minutesStr] = time.split(':');
        let hours = parseInt(hoursStr, 10);
        let minutes = parseInt(minutesStr, 10);

        // Subtract one minute
        if (minutes === 0) {
            minutes = 59;
            if (hours === 0) {
                hours = 23;
            } else {
                hours -= 1;
            }
        } else {
            minutes -= 1;
        }

        // Format back to 'HH:mm'
        const adjustedHoursStr = hours.toString().padStart(2, '0');
        const adjustedMinutesStr = minutes.toString().padStart(2, '0');

        return `${adjustedHoursStr}:${adjustedMinutesStr}`;
    }

    const cleanTransitPolicy = (policy: TransitPolicy): TransitPolicy => {
        // Deep copy to avoid mutating the original policy
        const policyCopy = JSON.parse(JSON.stringify(policy));

        // Remove undefined and null properties recursively
        const removeUndefined = (obj: any) => {
            if (Array.isArray(obj)) {
                obj.forEach(removeUndefined);
            } else if (typeof obj === 'object' && obj !== null) {
                Object.keys(obj).forEach((key) => {
                    if (obj[key] && typeof obj[key] === 'object') {
                        removeUndefined(obj[key]);
                    } else if (obj[key] === undefined || obj[key] === null) {
                        delete obj[key];
                    }
                });
            }
        };

        removeUndefined(policyCopy);

        // Remove disabled rules, rules with no actions, and internal state variables
        policyCopy.rules = policyCopy.rules
            ?.filter((rule: any) => {
                const hasAction = rule.action && Object.keys(rule.action).length > 0;
                return rule.enabled !== false && hasAction;
            })
            .map((rule: any) => {
                delete rule.editorKey;

                // Adjust the end time in timeRange to be exclusive of the last minute
                if (rule.criteria?.timeRange) {
                    const [start, end] = rule.criteria.timeRange.split('-');
                    const adjustedEnd = adjustEndTime(end);
                    rule.criteria.timeRange = `${start}-${adjustedEnd}`;
                }

                return rule;
            });

        return policyCopy;
    };

    const updateTransitPolicyRule = (
        editorKey: string,
        updateFn: (rule: TransitPolicyRule) => TransitPolicyRule
    ) => {
        setTransitPolicy((prevPolicy) => {
            if (!prevPolicy) return prevPolicy;
            return {
                ...prevPolicy,
                rules: prevPolicy.rules?.map((rule) =>
                    rule.editorKey === editorKey ? updateFn(rule) : rule
                ),
            };
        });
    };

    // Entry Settings
    const [entryRfidCodeList, setEntryRfidCodeList] = useState<string[]>([]);
    const [entryPermission, setEntryPermission] = useState<PERMISSION>(PERMISSION.DENY);
    const [presentSelectEntryRfidModal, dismissSelectEntryRfidModal] = useIonModal(SelectRfidModal, {
        deviceId,
        disabledRfidCodes: entryRfidCodeList,
        dismiss: (rfidCode: string, role: string) => {
            if (role === 'confirm') {
                addEntryRfidCode(rfidCode);
            }
            dismissSelectEntryRfidModal(rfidCode, role);
        },
    });

    const entryRule = transitPolicy?.rules?.find((rule) => rule.editorKey === 'entry');
    const hasEntryRule = !!entryRule;

    useEffect(() => {
        if (entryRule) {
            if (entryRule.enabled !== false) {
                if (entryRule.criteria?.rfidCode) {
                    setEntryPermission(PERMISSION.ALLOWLIST);
                    setEntryRfidCodeList(entryRule.criteria.rfidCode as string[]);
                } else {
                    setEntryPermission(PERMISSION.ALLOW);
                }
            } else {
                setEntryPermission(PERMISSION.DENY);
            }
        } else {
            setEntryPermission(PERMISSION.DENY);
        }
    }, [transitPolicy, entryRule]);

    const addEntryRfidCode = (rfidCode: string) => {
        setEntryRfidCodeList((prevList) => {
            const newList = [...prevList, rfidCode];
            if (entryPermission === PERMISSION.ALLOWLIST) {
                updateEntryRuleCriteria({ rfidCode: newList });
            }
            return newList;
        });
    };

    const removeEntryRfidCode = (rfidCode: string) => {
        setEntryRfidCodeList((prevList) => {
            const newList = prevList.filter((code) => code !== rfidCode);
            if (entryPermission === PERMISSION.ALLOWLIST) {
                updateEntryRuleCriteria({ rfidCode: newList });
            }
            return newList;
        });
    };

    const updateEntryRuleCriteria = (criteriaUpdate: Partial<TransitPolicyRule['criteria']>) => {
        updateTransitPolicyRule('entry', (rule) => ({
            ...rule,
            criteria: {
                ...rule.criteria,
                ...criteriaUpdate,
            },
        }));
    };

    const setEntryPermissionState = (permission: PERMISSION) => {
        setEntryPermission(permission);
        updateTransitPolicyRule('entry', (rule) => {
            let criteria = { ...rule.criteria };
            if (permission === PERMISSION.ALLOWLIST) {
                criteria.rfidCode = entryRfidCodeList;
            } else {
                delete criteria.rfidCode;
            }
            return {
                ...rule,
                criteria,
                enabled: permission !== PERMISSION.DENY,
            };
        });
    };

    const hasEntryChime = !!transitPolicy?.rules?.find(
        (rule) => rule.editorKey === 'entry' && rule.action?.sound
    );

    const setEntryChime = (enabled: boolean) => {
        updateTransitPolicyRule('entry', (rule) => ({
            ...rule,
            action: {
                ...rule.action,
                sound: enabled ? 'bell' : undefined,
            },
        }));
    };

    // Contraband Settings
    const hasContrabandAlarm = !!transitPolicy?.rules?.find(
        (rule) => rule.editorKey === 'contraband' && rule.action?.sound
    );
    const hasContrabandBlock = !!transitPolicy?.rules?.find(
        (rule) => rule.editorKey === 'contraband' && rule.action?.lock
    );

    const setContrabandAlarm = (enabled: boolean) => {
        updateTransitPolicyRule('contraband', (rule) => ({
            ...rule,
            action: {
                ...rule.action,
                sound: enabled ? 'alarm' : undefined,
            },
        }));
    };

    const setContrabandBlock = (enabled: boolean) => {
        updateTransitPolicyRule('contraband', (rule) => ({
            ...rule,
            action: {
                ...rule.action,
                lock: enabled ? true : undefined,
            },
        }));
    };

    // Exit Settings
    const [exitRfidCodeList, setExitRfidCodeList] = useState<string[]>([]);
    const [exitPermission, setExitPermission] = useState<PERMISSION>(PERMISSION.DENY);
    const [presentSelectExitRfidModal, dismissSelectExitRfidModal] = useIonModal(SelectRfidModal, {
        deviceId,
        disabledRfidCodes: exitRfidCodeList,
        dismiss: (rfidCode: string, role: string) => {
            if (role === 'confirm') {
                addExitRfidCode(rfidCode);
            }
            dismissSelectExitRfidModal(rfidCode, role);
        },
    });

    const exitRule = transitPolicy?.rules?.find((rule) => rule.editorKey === 'exit');
    const hasExitRule = !!exitRule;

    const [exitTimeOption, setExitTimeOption] = useState<'anyTime' | 'specificTimes'>('anyTime');
    const [exitTimeRange, setExitTimeRange] = useState<{ start: string; end: string }>({
        start: '07:00',
        end: '18:00',
    });

    useEffect(() => {
        if (exitRule) {
            if (exitRule.enabled !== false) {
                if (exitRule.criteria?.rfidCode) {
                    setExitPermission(PERMISSION.ALLOWLIST);
                    setExitRfidCodeList(exitRule.criteria.rfidCode as string[]);
                } else {
                    setExitPermission(PERMISSION.ALLOW);
                }

                if (exitRule.criteria?.timeRange) {
                    setExitTimeOption('specificTimes');
                    const [start, end] = (exitRule.criteria.timeRange as string).split('-');
                    setExitTimeRange({ start, end });
                } else {
                    setExitTimeOption('anyTime');
                }
            } else {
                setExitPermission(PERMISSION.DENY);
                setExitTimeOption('anyTime');
            }
        } else {
            setExitPermission(PERMISSION.DENY);
            setExitTimeOption('anyTime');
        }
   }, [transitPolicy, exitRule]);

    const addExitRfidCode = (rfidCode: string) => {
        setExitRfidCodeList((prevList) => {
            const newList = [...prevList, rfidCode];
            if (exitPermission === PERMISSION.ALLOWLIST) {
                updateExitRuleCriteria({ rfidCode: newList });
            }
            return newList;
        });
    };

    const removeExitRfidCode = (rfidCode: string) => {
        setExitRfidCodeList((prevList) => {
            const newList = prevList.filter((code) => code !== rfidCode);
            if (exitPermission === PERMISSION.ALLOWLIST) {
                updateExitRuleCriteria({ rfidCode: newList });
            }
            return newList;
        });
    };

    const updateExitRuleCriteria = (criteriaUpdate: Partial<TransitPolicyRule['criteria']>) => {
        updateTransitPolicyRule('exit', (rule) => new TransitPolicyRule({
            ...rule,
            criteria: {
                ...rule.criteria,
                ...criteriaUpdate,
            },
        }));
    };

    const setExitPermissionState = (permission: PERMISSION) => {
        setExitPermission(permission);
        updateTransitPolicyRule('exit', (rule) => {
            let criteria: TransitPolicyRuleCriteria = { ...rule.criteria };
            if (permission === PERMISSION.ALLOWLIST) {
                criteria.rfidCode = exitRfidCodeList;
            } else {
                delete criteria.rfidCode;
            }
            return new TransitPolicyRule({
                ...rule,
                criteria,
                enabled: permission !== PERMISSION.DENY,
            });
        });
    };

    const setExitTimeOptionState = (option: 'anyTime' | 'specificTimes') => {
        setExitTimeOption(option);
        if (option === 'anyTime') {
            updateExitRuleCriteria({ timeRange: undefined });
        } else {
            updateExitRuleCriteria({ timeRange: `${exitTimeRange.start}-${exitTimeRange.end}` });
        }
    };

    const setExitTimeRangeState = (start: string, end: string) => {
        setExitTimeRange({ start, end });
        if (exitTimeOption === 'specificTimes') {
            updateExitRuleCriteria({ timeRange: `${start}-${end}` });
        }
    };

    const [presentJsonVieweModal, dismissJsonViewerModal] = useIonModal(JsonViewerModal, {
        data: () => cleanTransitPolicy(transitPolicy!),
        dismiss: () => dismissJsonViewerModal()
    });

    const showCode = async () => {
        presentJsonVieweModal({
            presentingElement: await modalController.getTop()
        });
    };

    const canCreatePolicy =
        transitPolicy &&
        !(
            (entryPermission === PERMISSION.ALLOWLIST && entryRfidCodeList.length === 0) ||
            (exitPermission === PERMISSION.ALLOWLIST && exitRfidCodeList.length === 0)
        );

    return (
        <IonPage>
            <IonHeader translucent>
                <IonToolbar>
                    <IonButtons slot="start">
                        {slideIndex !== PAGES.POLICY_TYPE && (
                            <IonButton onClick={() => swiperRef.current?.slidePrev()}>
                                <IonIcon icon={arrowBack} />
                            </IonButton>
                        )}
                    </IonButtons>
                    <IonTitle>Add Door Policy <BetaChip /></IonTitle>
                    <IonButtons slot="primary">
                        <IonButton onClick={() => dismiss()}>
                            <IonIcon slot="icon-only" icon={close} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <Swiper
                className={styles.swiper}
                allowTouchMove={false}
                onSwiper={(swiper: SwiperClass) => {
                    swiperRef.current = swiper;
                }}
                onSlideChange={(swiper: SwiperClass) => setSlideIndex(swiper.activeIndex)}
            >
                <SwiperSlide className={styles.slide}>
                    <IonContent color="light">
                        {Object.entries(POLICY_TEMPLATE).map(([key, template]) => (
                            <IonList inset key={key}>
                                <IonItem button onClick={() => selectPolicyTemplate(key)}>
                                    <IonIcon slot="start" src={template.icon} />
                                    <IonLabel>
                                        <h2>{template.defaults.policyName}</h2>
                                        <IonNote>{template.description}</IonNote>
                                    </IonLabel>
                                </IonItem>
                            </IonList>
                        ))}
                        <IonNote color="medium" className="ion-padding-horizontal">
                            <p>
                                Most common Door Policies can be added here. If you require more advanced bespoke policies, such as per-cat curfews, please
                                contact us, and we'll be able to help.
                            </p>
                        </IonNote>
                    </IonContent>
                </SwiperSlide>

                <SwiperSlide className={styles.slide}>
                    <IonContent color="light">
                        {hasEntryRule && (
                            <PermissionSettings
                                deviceId={deviceId}
                                title="Entry"
                                permission={entryPermission}
                                setPermission={selectedTemplateKey !== 'UNLOCK_FOR_ALL' ? setEntryPermissionState : undefined}
                                rfidCodeList={entryRfidCodeList}
                                addRfidCode={async () =>
                                    presentSelectEntryRfidModal({
                                        presentingElement: await modalController.getTop(),
                                    })
                                }
                                removeRfidCode={removeEntryRfidCode}
                                hasChime={hasEntryChime}
                                setChime={setEntryChime}
                            />
                        )}

                        {transitPolicy?.rules?.some((rule) => rule.editorKey === 'contraband') && (
                            <ContrabandSettings
                                hasContrabandAlarm={hasContrabandAlarm}
                                setContrabandAlarm={setContrabandAlarm}
                                hasContrabandBlock={hasContrabandBlock}
                                setContrabandBlock={setContrabandBlock}
                            />
                        )}

                        {hasExitRule && (
                            <PermissionSettings
                                deviceId={deviceId}
                                title="Exit"
                                permission={exitPermission}
                                setPermission={setExitPermissionState}
                                rfidCodeList={exitRfidCodeList}
                                addRfidCode={async () =>
                                    presentSelectExitRfidModal({
                                        presentingElement: await modalController.getTop(),
                                    })
                                }
                                removeRfidCode={removeExitRfidCode}
                                timeSettings={{
                                    exitTimeOption,
                                    setExitTimeOption: setExitTimeOptionState,
                                    exitTimeRange,
                                    setExitTimeRange: setExitTimeRangeState,
                                }}
                            />
                        )}

                        <h3 className="ion-padding-horizontal">Display</h3>
                        <IonList inset>
                            <IonItem>
                                <IonInput
                                    label="Policy Name"
                                    maxlength={32}
                                    value={policyName}
                                    onIonChange={(e) => setPolicyName(e.detail.value!)}
                                />
                            </IonItem>
                        </IonList>
                        <IonList inset>
                            <IonItem button detail={false} onClick={createPolicy} disabled={!canCreatePolicy}>
                                <IonTitle color={canCreatePolicy ? 'primary' : 'medium'}>Create</IonTitle>
                            </IonItem>
                        </IonList>
                        {transitPolicy && developerMode && <IonButton fill="clear" onClick={showCode} expand={'full'}>Show Policy Code</IonButton>}
                    </IonContent>
                </SwiperSlide>
            </Swiper>
        </IonPage >
    );
};

interface PermissionSettingsProps {
    deviceId: string;
    title: string;
    permission: PERMISSION;
    setPermission?: (permission: PERMISSION) => void;
    rfidCodeList: string[];
    addRfidCode: () => void;
    removeRfidCode: (rfidCode: string) => void;
    hasChime?: boolean;
    setChime?: (enabled: boolean) => void;
    timeSettings?: {
        exitTimeOption: 'anyTime' | 'specificTimes';
        setExitTimeOption: (option: 'anyTime' | 'specificTimes') => void;
        exitTimeRange: { start: string; end: string };
        setExitTimeRange: (start: string, end: string) => void;
    };
}

const PermissionSettings: React.FC<PermissionSettingsProps> = ({
    deviceId,
    title,
    permission,
    setPermission,
    rfidCodeList,
    addRfidCode,
    removeRfidCode,
    hasChime,
    setChime,
    timeSettings,
}) => (
    <>
        <h3 className="ion-padding-horizontal">{title}</h3>
        {setPermission && <IonList inset>
            <IonRadioGroup value={permission} onIonChange={(e) => setPermission(e.detail.value)}>
                <IonItem>
                    <IonRadio value="allow" labelPlacement="end" justify="start">
                        Allow all
                    </IonRadio>
                </IonItem>
                <IonItem>
                    <IonRadio value="deny" labelPlacement="end" justify="start">
                        Deny all
                    </IonRadio>
                </IonItem>
                <IonItem>
                    <IonRadio value="allowlist" labelPlacement="end" justify="start">
                        Specific microchips{permission === PERMISSION.ALLOWLIST && ':'}
                    </IonRadio>
                </IonItem>
            </IonRadioGroup>
            {permission === PERMISSION.ALLOWLIST && (
                <>
                    {rfidCodeList.map((rfidCode) => (
                        <IonItem key={rfidCode} button detail={false} onClick={() => removeRfidCode(rfidCode)}>
                            <IonIcon slot="start" icon={removeCircle} color="danger" />
                            <RfidChip rfidCode={rfidCode} deviceId={deviceId} />
                        </IonItem>
                    ))}
                    <IonItem button detail={false} onClick={addRfidCode}>
                        <IonIcon slot="start" icon={addOutline} />
                        <IonText color="primary">Add Microchip</IonText>
                    </IonItem>
                </>
            )}
        </IonList>}

        {hasChime !== undefined && setChime && (
            <IonList inset>
                <IonItem>
                    <IonIcon icon={musicalNoteOutline} slot="start" />
                    <IonToggle checked={hasChime} onIonChange={(e) => setChime(e.detail.checked)}>
                        <IonLabel>{title} Chime</IonLabel>
                    </IonToggle>
                </IonItem>
            </IonList>
        )}

        {hasChime && (
            <IonNote color="medium" className="ion-margin-horizontal">
                The {title.toLowerCase()} chime plays when {title.toLowerCase()} is approved. Some cats may
                need time to get used to this.
            </IonNote>
        )}

        {timeSettings && (
            <IonList inset>
                <IonRadioGroup
                    value={timeSettings.exitTimeOption}
                    onIonChange={(e) => timeSettings.setExitTimeOption(e.detail.value)}
                >
                    <IonItem>
                        <IonRadio value="anyTime" labelPlacement="end" justify="start">
                            Any time
                        </IonRadio>
                    </IonItem>
                    <IonItem>
                        <IonRadio value="specificTimes" labelPlacement="end" justify="start">
                            Specific times{timeSettings.exitTimeOption === 'specificTimes' && ':'}
                        </IonRadio>
                    </IonItem>
                </IonRadioGroup>
                {timeSettings.exitTimeOption === 'specificTimes' && (
                    <>
                        <IonItem>
                            <IonLabel>From</IonLabel>
                            <IonDatetimeButton datetime="start-time" />
                            <IonPopover keepContentsMounted={true}>
                                <IonDatetime
                                    id="start-time"
                                    presentation="time"
                                    minuteValues="0,15,30,45"
                                    value={timeSettings.exitTimeRange.start}
                                    onIonChange={(e) =>
                                        timeSettings.setExitTimeRange(e.detail.value as string, timeSettings.exitTimeRange.end)
                                    }
                                />
                            </IonPopover>
                        </IonItem>
                        <IonItem>
                            <IonLabel>To</IonLabel>
                            <IonDatetimeButton datetime="end-time" />
                            <IonPopover keepContentsMounted={true}>
                                <IonDatetime
                                    id="end-time"
                                    presentation="time"
                                    minuteValues="0,15,30,45"
                                    value={timeSettings.exitTimeRange.end}
                                    onIonChange={(e) =>
                                        timeSettings.setExitTimeRange(timeSettings.exitTimeRange.start, e.detail.value as string)
                                    }
                                />
                            </IonPopover>
                        </IonItem>
                    </>
                )}
            </IonList>
        )}
        {timeSettings?.exitTimeOption === 'specificTimes' && (
            <IonNote color="medium" className="ion-margin-horizontal">
                A curfew will be in effect between {timeSettings.exitTimeRange.end} and {timeSettings.exitTimeRange.start} each day.
            </IonNote>
        )}
    </>
);

interface ContrabandSettingsProps {
    hasContrabandAlarm: boolean;
    setContrabandAlarm: (enabled: boolean) => void;
    hasContrabandBlock: boolean;
    setContrabandBlock: (enabled: boolean) => void;
}

const ContrabandSettings: React.FC<ContrabandSettingsProps> = ({
    hasContrabandAlarm,
    setContrabandAlarm,
    hasContrabandBlock,
    setContrabandBlock,
}) => (
    <IonList inset>
        <IonItem>
            <IonIcon icon={banOutline} slot="start" />
            <IonToggle checked={hasContrabandBlock} onIonChange={(e) => setContrabandBlock(e.detail.checked)}>
                <IonLabel>Block Contraband</IonLabel>
                <IonNote color="medium" className="ion-text-wrap">
                    Deny entry when contraband is detected.
                </IonNote>
            </IonToggle>
        </IonItem>
        <IonItem>
            <IonIcon icon={volumeHighOutline} slot="start" />
            <IonToggle
                checked={hasContrabandAlarm}
                onIonChange={(e) => setContrabandAlarm(e.detail.checked)}
            >
                <IonLabel>Contraband Alarm</IonLabel>
                <IonNote color="medium" className="ion-text-wrap">
                    Play a sound when contraband is detected.
                </IonNote>
            </IonToggle>
        </IonItem>
    </IonList>
);

export default AddDoorPolicyModal;