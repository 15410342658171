import { useQuery, useMutation, useQueryClient, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { Storage } from '@ionic/storage';

export function useDeveloperModeQuery(options?: UseQueryOptions<boolean, Error>) {
    return useQuery({
        queryKey: ['developerMode'],
        queryFn: async () => {
            const storage = new Storage();
            await storage.create();
            const devMode = await storage.get('developerMode');
            return devMode || false;
        },
        ...options
    });
}

export function useUpdateDeveloperModeMutation(options?: UseMutationOptions<boolean, Error, boolean>) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (developerMode: boolean) => {
            const storage = new Storage();
            await storage.create();
            await storage.set('developerMode', developerMode);
            return developerMode;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['developerMode'] });
        },
        ...options
    });
}