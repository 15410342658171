import { useState } from "react";
import { IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonList, IonItem, IonLabel, IonPage, IonIcon, IonRadioGroup, IonRadio, IonNote, IonCard, IonCardHeader, IonCardContent, IonCardTitle } from "@ionic/react";
import { close } from "ionicons/icons";
import { useDeviceQuery } from "../query/deviceQuery";
import { useDeviceFirmwareChannelMutation } from "../query/deviceFirmwareChannelMutation";
import useUX from '../utils/ux';

const DeviceFirmwareChannelModal = ({ dismiss, deviceId }: { dismiss: (data?: string | null | undefined | number, role?: string) => void, deviceId: string }) => {
    const deviceQuery = useDeviceQuery(deviceId);
    const deviceMutation = useDeviceFirmwareChannelMutation(deviceId);
    const { presentResult } = useUX();
    const [selectedChannel, setSelectedChannel] = useState(deviceQuery.data?.firmwareChannel || 'standard');

    const saveFirmwareChannel = async () => {
        presentResult(async () => {
            await deviceMutation.mutateAsync(selectedChannel);
            dismiss(selectedChannel, 'select');
        }, "Firmware Channel Updated");
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Firmware Channel</IonTitle>
                    <IonButtons slot="primary">
                        <IonButton onClick={() => dismiss(null, 'cancel')}>
                            <IonIcon slot="icon-only" icon={close} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonCard>
                    <IonCardContent>
                        <p>The <strong>Standard</strong> channel offers reliable, thoroughly tested updates for everyday use.</p>
                        <p>Alternatively, choose the <strong>Experimental</strong> channel if you’d like early access to new features, the latest prey detection models, and other improvements - keeping in mind that these updates may be less stable.</p>
                    </IonCardContent>
                </IonCard>
                <IonList>
                    <IonRadioGroup value={selectedChannel} onIonChange={e => setSelectedChannel(e.detail.value)}>
                        <IonItem>
                            <IonRadio value="standard">
                                <IonLabel>Standard</IonLabel>
                                <IonNote>Fully tested, stable updates.</IonNote>
                            </IonRadio>
                        </IonItem>
                        <IonItem>
                            <IonRadio value="experimental">
                                <IonLabel>Experimental</IonLabel>
                                <IonNote>Cutting-edge updates.</IonNote>
                            </IonRadio>
                        </IonItem>
                        {selectedChannel !== 'standard' && selectedChannel !== 'experimental' && (
                            <IonItem>
                                <IonRadio value={selectedChannel}>
                                    <IonLabel style={{ textTransform: 'capitalize' }}></IonLabel>
                                    <IonNote>Trialling specific functionality.</IonNote>
                                </IonRadio>
                            </IonItem>
                        )}
                    </IonRadioGroup>
                </IonList>
                <IonButton expand="block" onClick={saveFirmwareChannel}>
                    Save
                </IonButton>
            </IonContent>
        </IonPage>
    );
};

export default DeviceFirmwareChannelModal;
