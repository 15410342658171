import OCGatewayClient from "../client/OCGatewayClient";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { RfidProfile } from "../models/RfidProfile";

let subscribed = false;

export function useRfidProfileQuery(rfidCode: string, deviceId?: string) {
    const queryClient = useQueryClient();
    
    const query =  useQuery({
        queryKey: ['getRfidProfile', rfidCode, deviceId].filter(Boolean),
        queryFn: async () => {
            return OCGatewayClient.request('getRfidProfile', {
                rfidCode,
                deviceId,
                subscribe: true
            }) as Promise<RfidProfile>;
        },
        staleTime: 1000 * 60 * 5,
        gcTime: 1000 * 60 * 60 * 24,
        networkMode: 'always',
        retry: false
    });

    if (!subscribed) {
        OCGatewayClient.user$.subscribe(user => {
            console.log(`(rfidProfileQuery) User changed, invalidating RFID profiles...`);
            queryClient.invalidateQueries({
                queryKey: ['getRfidProfile']
            });
        });

        OCGatewayClient.socket.on('rfidProfileUpdate', (data: any) => {
            console.log('rfidProfileUpdate, invalidating query', data);
            queryClient.invalidateQueries({
                queryKey: ['getRfidProfile', data.rfidCode]
            });
        });

        subscribed = true;
    }

    return query;
}

export function useRfidProfileMutation() {
    const queryClient = useQueryClient();
    
    const mutation = useMutation({
        mutationFn: async (rfidProfile: any) => {
            return OCGatewayClient.request('updateRfidProfile', rfidProfile);
        },
        onMutate: async (rfidProfile: any) => {
            await queryClient.cancelQueries({ queryKey: ['getRfidProfile', rfidProfile.rfidCode] });

            const previousRfidProfile = queryClient.getQueryData(['getRfidProfile', rfidProfile.rfidCode]);

            queryClient.setQueryData(['getRfidProfile', rfidProfile.rfidCode], (queryData: any) => {
                return Object.assign({}, queryData, rfidProfile);
            });

            return { previousRfidProfile };
        },
        onError: (error, variables, context) => {
            queryClient.setQueryData(['getRfidProfile', variables.rfidCode], context?.previousRfidProfile);
        },
        onSettled: (data, error, variables, context) => {
            queryClient.invalidateQueries({ queryKey: ['getRfidProfile', variables.rfidCode] });
        }
    });

    return mutation;
};